import Register from "../components/public/register";
import Login from "../components/public/login";
import ForgotPassword from "../components/public/forgotPassword";
import PasswordConfirmation from "../components/public/passwordConfirmation";
import TermsAndConditions from "../components/public/termsAndConditions";
import ChangePasswordPublic from "../components/public/changePasswordPublic";
import PrivacyPolicy from "../components/public/privacyPolicy";

const publicRoutes = [
	{ path: "/login", element: <Login />, exact: true },
	{ path: "/register", element: <Register /> },
	{ path: "/forgotPassword", element: <ForgotPassword /> },
	{ path: "/changePassword", element: <ChangePasswordPublic /> },
	{ path: "/confirmation", element: <PasswordConfirmation /> },
	{ path: "/terms-and-conditions", element: <TermsAndConditions /> },
	{ path: "/privacy-policy", element: <PrivacyPolicy /> },
];

export default publicRoutes;
