import { useEffect, useState } from "react";
import Domains from "../../common/maturityAssessment/domains";
import { FrameworkDomain } from "../../../utils/interfaces";
import createAxiosInstance from "../../../utils/axiosConfig";
import { framework_domains } from "../../../utils/endpoints";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../context/authContext";
import { useCases } from "../../../utils/constants";
import ChatHeader from "../../common/chat/chatHeader";
import { useChat } from "../../../context/chatContext";
import ChatHistory from "../../common/chatHistory";
import Footer from "../../common/footer";

const MaturityDomains = () => {
	const [domains, setDomains] = useState<FrameworkDomain[]>([]);
	const { logout } = useAuth();
	const navigate = useNavigate();
	const { frameworkId } = useParams();
	const { startChat } = useChat();

	const fetchDomains = async (frameworkId: number) => {
		// if (!selectedFramework) return;
		try {
			const domains = await createAxiosInstance().get(
				framework_domains(frameworkId)
			);
			if (domains.status == 401 || domains.status == 403) {
				// 401 is for expired token
				// 403 is for forbidden access (blocked by GPT.)
				console.log("use token expired");
				// logout();
				// navigate("/");
				// window.location.href = "/";
				return;
			}
			setDomains(domains?.data);
		} catch (e) {
			console.log(e);
		}
	};

	// fetchDomains();

	useEffect(() => {
		fetchDomains(Number(frameworkId));
	}, [frameworkId]);

	return (
		<>
			<ChatHeader
				useCase={useCases[useCases.length - 1].value}
				selectUseCase={startChat}
			/>
			<main className="d-flex flex-column min-vh-65">
				<Domains
					domains={domains}
					handleQuit={() => navigate(-1)}
					frameworkId={Number(frameworkId)}
				/>
				<Footer />
			</main>
			<ChatHistory
				onNavigate={() => {
					navigate("/");
				}}
			/>
		</>
	);
};

export default MaturityDomains;
