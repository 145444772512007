import React, { useState } from "react";
import { Question, QuestionFlow } from "../../../../utils/interfaces";
import createAxiosInstance from "../../../../utils/axiosConfig";
import { save_score } from "../../../../utils/endpoints";
import { useAuth } from "../../../../context/authContext";

const Questions = ({
	selectedFlow,
	questions,
	handleQuit,
	handleAlert,
}: {
	selectedFlow: QuestionFlow;
	questions: Question[];
	handleQuit: () => void;
	handleAlert: (alert: {
		message: string;
		show: boolean;
		type: string;
	}) => void;
}) => {
	// const navigate = useNavigate();
	const { userId, logout } = useAuth();
	const [loading, setLoading] = useState<boolean>(false);
	// const [questions, setQuestions] = useState<Question[]>([]);
	const [qaPairs, setQaPairs] = useState<{ [key: string]: string }>({});
	const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
	const optionScores: { [key: string]: number } = {
		None: 0,
		Partial: 1,
		"Risk Informed": 2,
		Repeatable: 3,
		Adaptive: 4,
	};

	const [showConstraintQuestion, setShowConstraintQuestion] = useState(false);
	const [showConstraintInput, setShowConstraintInput] = useState(false);
	const [userConstraint, setUserConstraint] = useState<string>("");
	const [constraintResponse, setConstraintResponse] = useState<string>("");

	// useEffect(() => {
	// 	const getQuestions = async () => {
	// 		if (!selectedFlow) return;

	// 		try {
	// 			const response = await createAxiosInstance().get(
	// 				get_questions_by_flow_id(selectedFlow.id)
	// 			);
	// 			if (response.status == 401 || response.status == 403) {
	// 				// 401 is for expired token
	// 				// 403 is for forbidden access (blocked by GPT.)
	// 				console.log("user token expired");
	// 				logout();
	// 				window.location.href = "/";
	// 				// navigate("/");
	// 				return;
	// 			}

	// 			setQuestions(response.data);
	// 		} catch (e: any) {
	// 			if (e.response.status == 402) {
	// 				console.log(e.response.data.detail);
	// 				handleQuit();
	// 				return;
	// 			}
	// 			console.log(e);
	// 		}
	// 	};

	// 	getQuestions();
	// }, [selectedFlow]);

	const calculateTopBarWidth = (
		currentIndex: number,
		totalQuestions: number
	) => {
		if (totalQuestions === 0) return "0%";
		return `${((currentIndex + 1) / totalQuestions) * 98}%`;
	};

	const handleOptionChange = (option: string) => {
		const currentQuestion = questions[currentQuestionIndex];
		setQaPairs((prevQaPairs) => ({
			...prevQaPairs,
			[currentQuestion.question_text]: option,
		}));
	};

	const showAlert = () => {
		handleAlert({
			message: "Please ensure you have made a selection.",
			show: true,
			type: "warning",
		});
	};

	const hideAlert = () => {
		handleAlert({
			message: "",
			show: false,
			type: "",
		});
	};

	const handleNext = async () => {
		if (
			currentQuestionIndex < questions.length - 1 &&
			Object.keys(qaPairs).length === currentQuestionIndex + 1
		) {
			const currentQuestion = questions[currentQuestionIndex];
			const selectedOption = qaPairs[currentQuestion?.question_text];
			console.log(selectedOption, "selected option", currentQuestion);
			// store answer in DB...
			try {
				await createAxiosInstance().post("/user_answers", {
					// flow_id: selectedFlow.id,
					user_id: userId,
					question_id: currentQuestion?.id,
					answer_key: optionScores[selectedOption],
					attempt_key: 1,
				});
			} catch (e) {
				console.log(e);
			} finally {
				setCurrentQuestionIndex(currentQuestionIndex + 1);
				hideAlert();
			}
		} else {
			console.log("here now");
			if (
				Object.keys(qaPairs).length === questions.length ||
				currentQuestionIndex < Object.keys(qaPairs).length
			) {
				const currentQuestion = questions[currentQuestionIndex];
				const selectedOption = qaPairs[currentQuestion?.question_text];
				// store answer in DB...

				try {
					await createAxiosInstance().post("/user_answers", {
						// flow_id: selectedFlow.id,
						user_id: userId,
						question_id: currentQuestion?.id,
						answer_key: optionScores[selectedOption],
						attempt_key: 1,
					});
				} catch (e) {
					console.log(e);
				} finally {
					if (
						currentQuestionIndex + 1 === questions.length &&
						!showConstraintInput
					) {
						setShowConstraintQuestion(true);
					} else {
						setCurrentQuestionIndex(currentQuestionIndex + 1);
						hideAlert();
					}
				}
			}

			if (Object.keys(qaPairs).length < currentQuestionIndex + 1) {
				// show alert as user has not selected any option...
				console.log("showing alert");
				showAlert();
			}
		}
	};

	const handlePrev = () => {
		if (currentQuestionIndex > 0 && !showConstraintQuestion) {
			setCurrentQuestionIndex(currentQuestionIndex - 1);
		} else {
			setShowConstraintQuestion(false);
			setShowConstraintInput(false);
			setUserConstraint("");
			setConstraintResponse("");
		}
	};

	const handleSubmit = async () => {
		// Handle the submission logic here
		setLoading(true);

		const currentQuestion = questions[currentQuestionIndex];
		const selectedOption = qaPairs[currentQuestion?.question_text];

		// store final answer in DB...
		try {
			await createAxiosInstance().post("/user_answers", {
				// flow_id: selectedFlow.id,
				user_id: userId,
				question_id: currentQuestion?.id,
				answer_key: optionScores[selectedOption],
				attempt_key: 1,
			});
		} catch (e) {
			console.log(e);
		} finally {
			// save score...
			let totalScore = 0;
			Object.values(qaPairs).forEach((selectedOption) => {
				totalScore += optionScores[selectedOption] || 0;
			});

			// save score and save user_constraint input...!
			try {
				const response = await createAxiosInstance().post(save_score, {
					score: totalScore / questions.length,
					flow_id: selectedFlow.id,
					user_id: userId,
				});

				setLoading(false);

				if (response.status == 401 || response.status == 403) {
					// 401 is for expired token
					// 403 is for forbidden access (blocked by GPT.)
					console.log("user token expired");

					logout();
					window.location.href = "/";
					// navigate("/");
					return;
				}

				localStorage.setItem(
					`${selectedFlow.id}`,
					JSON.stringify(response.data)
				);

				// Save user_constraint separately
				if (userConstraint) {
					await createAxiosInstance().post("/user_constraints", {
						user_id: userId,
						flow_id: selectedFlow.id,
						selected_option: constraintResponse === "Yes" ? true : false,
						constraint_text: userConstraint,
					});
				}
				// navigate(-1);
				// window.location.href = "/";
				handleQuit();
			} catch (e: any) {
				console.log(e, "error");
			} finally {
				setLoading(false);
			}
		}

		// You can add additional logic such as API calls or navigation
	};

	const handleConstraintResponse = (response: string) => {
		setConstraintResponse(response);
		if (response === "Yes") {
			setShowConstraintInput(true);
		} else {
			setShowConstraintInput(false);
			setUserConstraint("None");
		}
	};

	const tooltipContent = {
		None: {
			governance: [
				"Nothing formal exists regarding risk governance and/or cybersecurity management practices in the organization.",
			],
			management: [
				"The organization has minimal awareness of cybersecurity risks at a broader level.",
			],
		},
		Partial: {
			governance: [
				"The organization's cybersecurity risk strategy is applied on an ad-hoc basis.",
				"Prioritization is done as best as possible but lacks formal objectives or measurable methods.",
			],
			management: [
				"Cybersecurity risk management is carried out sporadically and only on an as-needed basis.",
				"There may be a lack of processes for sharing cybersecurity information within the organization.",
			],
		},
		"Risk Informed": {
			governance: [
				"Risk management practices are approved by management but may not be formalized as organization-wide policy.",
				"The prioritization of cybersecurity activities and protection needs is guided by organizational risk objectives, the threat environment, and business or mission requirements.",
			],
			management: [
				"The organization is aware of cybersecurity risks but lacks a comprehensive, organization-wide approach to managing them.",
				"Cybersecurity is considered in some organizational objectives and programs, but not consistently across all levels.",
			],
		},
		Repeatable: {
			governance: [
				"The organization's risk management practices are formally approved and established as policy.",
				"Policies, processes, and procedures informed by risk are clearly defined, implemented as intended, and regularly reviewed.",
			],
			management: [
				"The organization has a comprehensive approach to managing cybersecurity risks, with information routinely shared throughout.",
				"Consistent methods are in place to effectively respond to changes in risk, and personnel have the necessary knowledge and skills for their roles.",
			],
		},
		Adaptive: {
			governance: [
				"The organization employs a comprehensive approach to managing cybersecurity risks, utilizing risk-informed policies, processes, and procedures to handle potential events.",
				"There is a clear understanding of how cybersecurity risks relate to organizational objectives, and this understanding informs decision-making.",
			],
			management: [
				"The organization adjusts its cybersecurity practices based on past and current activities, incorporating lessons learned and predictive indicators.",
				"Through continuous improvement and the integration of advanced technologies, the organization effectively responds to evolving and sophisticated threats in a dynamic technological environment.",
			],
		},
	};

	return (
		<div className="row h-100 w-100">
			<div className="col-md-12 w-100">
				<div className="card mx-auto text-center ">
					<div
						style={{
							borderTop: "4px solid #205493",
							borderTopLeftRadius: "14px",
							borderTopRightRadius: "14px",
							borderBottomRightRadius: "14px",
							width: calculateTopBarWidth(
								currentQuestionIndex,
								questions.length
							),
							marginLeft: "1%",
							justifyContent: "center",
						}}
					></div>
					<div className="card-body">
						<h2 className="my-3 my-md-4"> {selectedFlow.title}</h2>

						{showConstraintQuestion ? (
							<>
								<h3>
									Are there any constraints which you would like to specify?
								</h3>
								<ul className="list-group checklist pb-4">
									{["Yes", "No"].map((option, index) => (
										<li className="list-group-item" key={index}>
											<input
												className="form-check-input me-1"
												type="radio"
												name="listGroupRadio"
												id={`option${index}`}
												onChange={() => handleConstraintResponse(option)}
											/>
											<label
												className="form-check-label"
												htmlFor={`option${index}`}
											>
												{option}
											</label>
										</li>
									))}
								</ul>

								{showConstraintInput && (
									<div className="form-floating mb-3 mt-3">
										<textarea
											rows={15}
											className="form-control"
											placeholder="Specify your constraints here..."
											value={userConstraint}
											onChange={(e) => setUserConstraint(e.target.value)}
										/>
										<label htmlFor="floatingInput">
											Specify your constraints here...
										</label>
									</div>
								)}
							</>
						) : (
							<>
								<div style={{ height: "10vh" }}>
									<p
										className="text-start"
										style={{
											fontSize: "1.5rem",
										}}
									>
										<span
											className="text-secondary"
											style={{ fontSize: "1.3rem" }}
										>{`${selectedFlow.description}-0${questions[currentQuestionIndex]?.order}: `}</span>
										{questions[currentQuestionIndex]?.question_text}
									</p>
								</div>

								<ul className="list-group checklist pb-4">
									{[
										"None",
										"Partial",
										"Risk Informed",
										"Repeatable",
										"Adaptive",
									].map((option, index) => (
										<li
											className="list-group-item d-flex align-items-center justify-content-start gap-3"
											key={index}
										>
											<input
												className="form-check-input me-1"
												type="radio"
												name="listGroupRadio"
												id={`option${index}`}
												checked={
													qaPairs[
														questions[currentQuestionIndex]?.question_text
													] === option
												}
												onChange={() => handleOptionChange(option)}
											/>
											<label
												className="form-check-label"
												htmlFor={`option${index}`}
											>
												{option}
											</label>
											<div className="tooltip-container">
												<i
													className="bi bi-info-circle text-secondary cursor-pointer"
													style={{ cursor: "pointer" }}
												></i>
												<div className="tooltip-content px-4">
													<h5>Cybersecurity Risk Governance</h5>
													{tooltipContent[
														option as keyof typeof tooltipContent
													].governance.map((sentence, i) => (
														<p key={i}>{sentence}</p>
													))}
													<h5>Cybersecurity Risk Management</h5>
													{tooltipContent[
														option as keyof typeof tooltipContent
													].management.map((sentence: string, i: number) => (
														<p key={i}>{sentence}</p>
													))}
												</div>
											</div>
										</li>
									))}
								</ul>
							</>
						)}

						<div className="d-flex justify-content-between">
							<div className="mb-3">
								{currentQuestionIndex > 0 && (
									<button
										type="submit"
										className="btn btn-primary w-10"
										onClick={handlePrev}
									>
										Prev
									</button>
								)}
							</div>

							<div className="mb-3">
								<button
									type="submit"
									className="btn btn-primary w-10"
									onClick={showConstraintQuestion ? handleSubmit : handleNext}
								>
									{showConstraintQuestion ? "Submit" : "Next"}
								</button>
							</div>
						</div>
					</div>
				</div>
				<div
					className="text-center"
					style={{
						position: "relative",
						zIndex: 1000,
					}}
				>
					<div className="mt-4 btn btn-link" onClick={handleQuit}>
						<i className="bi bi-arrow-left me-2"></i>Quit
					</div>
				</div>
			</div>
		</div>
	);
};

export default Questions;
