import React, { useEffect, useState } from "react";
import LogoIcon from "../../assets/svg/logoIcon";
import EnvelopeIcon from "../../assets/svg/envelopeIcon";
import LockIcon from "../../assets/svg/lockIcon";
import { validate } from "../../utils/formValidate";
import { useAuth } from "../../context/authContext";
import { useNavigate } from "react-router-dom";
import createAxiosInstance from "../../utils/axiosConfig";
import { login_user } from "../../utils/endpoints";
import Alert from "../common/alerts/alert";
import Footer from "../common/footer";

const Login: React.FC = () => {
	const { login, isAuthenticated } = useAuth();
	const navigate = useNavigate();

	const [formData, setFormData] = useState({
		password: "",
		email: "",
	});

	const [errors, setErrors] = useState<any>({});
	const [, setResponse] = useState<any>({});
	const [responseError, setResponseError] = useState<any>({});
	const [alertVisible, setAlertVisible] = useState(false);

	const handleChange = (
		e: React.ChangeEvent<
			HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
		>
	) => {
		const { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
		validate({ [name]: value }, setErrors);
	};

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		if (!validate(formData, setErrors)) return;

		const payload = {
			password: formData.password,
			email: formData.email,
		};

		try {
			const axiosInstance = createAxiosInstance();
			const response = await axiosInstance.post(login_user, payload);
			setResponse(response.data);

			if (response.data?.access_token && response.data?.access_token !== "") {
				login(response.data?.id, response.data?.access_token, response.data);
			} else {
				setResponseError(response.data);
			}
			navigate("/chat");
			setResponseError({});
			// setAlertVisible(true);
		} catch (err) {
			console.log("i am running12333!");
			setResponseError(err);
			// setAlertVisible(true);
			console.log(err);
		}
	};

	useEffect(() => {
		if (responseError?.response?.data?.detail.length > 1) {
			setAlertVisible(true);
		}
		if (isAuthenticated) {
			navigate("/chat");
		}
	}, [responseError]);

	return (
		<section className="authentication h-100">
			<div className="row align-items-center justify-content-center min-vh-96 w-100">
				<div className="col-md-10 col-lg-9 col-xl-6">
					<div className="card mx-auto text-center">
						<div className="card-body">
							<div className="mb-3">
								<LogoIcon />
							</div>
							<h2 className="my-3 my-md-4">Sign In</h2>

							<form action="" onSubmit={handleSubmit}>
								<div className="form-floating mb-3 position-relative input-holder">
									<input
										type="email"
										className={`form-control ${
											errors.email ? "is-invalid" : ""
										}`}
										name="email"
										value={formData.email}
										onChange={handleChange}
										id="floatingInputEmail"
										placeholder="name@example.com"
									/>
									<label htmlFor="floatingInputEmail">Email</label>
									<span className="position-absolute icon-input">
										<EnvelopeIcon />
									</span>
								</div>

								<div className="form-floating mb-3 position-relative input-holder">
									<input
										type="password"
										id="floatingInputPassword"
										placeholder="********"
										name="password"
										value={formData.password}
										onChange={handleChange}
										className={`form-control ${
											errors.password ? "is-invalid" : ""
										}`}
									/>
									<label htmlFor="floatingInputPassword">Password</label>
									<span className="position-absolute icon-input">
										<LockIcon />
									</span>
									{errors.password && (
										<div className="text-danger mt-2">{errors.password}</div>
									)}
								</div>
								<div className="mb-3">
									<button
										// href="/chat"
										type="submit"
										className="btn btn-primary w-100"
										// onClick={handleLogin}
										disabled={Object.keys(errors).length > 0}
									>
										Sign in
									</button>
								</div>
							</form>
							<div className="mb-3">
								<a href="/forgotpassword" className="btn btn-link">
									Forgot Password
								</a>
							</div>
							<h3
								className="text-secondary my-3"
								style={{ fontWeight: "450", fontSize: "1.3rem" }}
							>
								Register for waitlist?{" "}
								<a
									href="/register"
									className="btn btn-link"
									// style={{ fontWeight: 400, fontSize: "1rem" }}
								>
									Click Here
								</a>
							</h3>
						</div>
					</div>
				</div>
				<Footer />
			</div>
			<Alert
				message={`${
					responseError?.response?.data?.detail.length > 1
						? responseError.response?.data?.detail
						: "Login successfull."
				}`}
				type={`${
					responseError?.response?.data?.detail.length > 1
						? "danger"
						: "success"
				}`}
				show={alertVisible}
				onClose={() => setAlertVisible(false)}
			/>
		</section>
	);
};

export default Login;
