import React from "react";
import { useCases } from "../../utils/constants";
import { UseCaseProps, UseCase } from "../../utils/interfaces";

const UseCaseCards = ({ selectUseCase }: UseCaseProps) => {
	return (
		<div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-4">
			{useCases.slice(0, 4).map((useCase: UseCase, key) => (
				<div className="col" key={`case_cards_${key}`}>
					<div
						className="card h-100 d-flex flex-column"
						onClick={() => selectUseCase(useCase?.value)}
						style={{ cursor: "pointer" }}
					>
						<div className="card-body d-flex flex-column">
							<div className="iconbox mb-3">{useCase?.sprite}</div>
							<div className="d-flex flex-column justify-content-center flex-grow-1">
								<h5 className="card-title mb-3">{useCase.name}</h5>
							</div>
							<div className="d-flex flex-column justify-content-end flex-grow-1">
								<p className="card-text text-secondary">
									{useCase.helpingText}
								</p>
							</div>
						</div>
					</div>
				</div>
			))}
		</div>
	);
};

export default UseCaseCards;
