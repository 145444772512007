import React, { useEffect, useState } from "react";
import { FrameworkCategory } from "../../../utils/interfaces";
import Messages from "../../common/messages";
import MaturityAssessment from "../../common/maturityAssessment";
import ChatFooter from "../../common/chat/chatFooter";
import ChatHeader from "../../common/chat/chatHeader";
import ChatHistory from "../../common/chatHistory";
import { useNavigate } from "react-router-dom";
import { useCases } from "../../../utils/constants";
import { useChat } from "../../../context/chatContext";
import createAxiosInstance from "../../../utils/axiosConfig";
import { nist_frameworks } from "../../../utils/endpoints";
import { useAuth } from "../../../context/authContext";
import Footer from "../../common/footer";

const MaturityAssessmentBody = () => {
	const [frameworks, setFrameworks] = useState<FrameworkCategory[]>([]);
	const navigate = useNavigate();
	const { startChat } = useChat();
	const { logout } = useAuth();

	const fetchFrameworks = async () => {
		try {
			const response = await createAxiosInstance().get(nist_frameworks);
			if (response.status == 401 || response.status == 403) {
				// 401 is for expired token
				// 403 is for forbidden access (blocked by GPT.)
				console.log("use token expired");
				// logout();
				// window.location.href = "/";
				return;
			}
			setFrameworks(response?.data);
			console.log(response?.data);
		} catch (error: any) {
			console.error("Error fetching maturity assessment frameworks:", error);
		}
	};

	useEffect(() => {
		fetchFrameworks();
	}, []);

	return (
		<>
			<ChatHeader
				useCase={useCases[useCases.length - 1].value}
				selectUseCase={startChat}
			/>
			<main className="d-flex flex-column min-vh-65">
				<MaturityAssessment frameworks={frameworks} />
				<Footer />
			</main>

			<ChatHistory
				onNavigate={() => {
					navigate("/");
				}}
			/>
		</>
	);
};

export default MaturityAssessmentBody;
