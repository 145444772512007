const Card = ({
	title,
	enabled,
	callbackFn,
	scoreObject,
	showAIButton = false,
	enableAIButton = false,
	showHistoryButton = false,
	forceHideHistoryButton = false,
	handleAIButtonClick = () => {},
	handleHistoryButtonClick = () => {},
}: {
	title: string;
	enabled: boolean;
	callbackFn: (param: any) => void;
	showAIButton?: any;
	enableAIButton?: boolean;
	showHistoryButton?: boolean;
	forceHideHistoryButton?: boolean;
	handleAIButtonClick?: () => void;
	scoreObject?: any;
	handleHistoryButtonClick?: () => void;
}) => {
	return (
		<div className="col">
			<div
				className="card d-flex flex-column "
				style={{ cursor: enabled ? "pointer" : "not-allowed" }}
			>
				<div
					className="card-body d-flex flex-column maturity-assessment-card"
					onClick={enabled ? callbackFn : () => {}}
					style={{ height: "13vh" }}
				>
					<div className="iconbox mb-3">{}</div>
					<div className="d-flex flex-column justify-content-center flex-grow-1">
						<h5 className="card-title mb-3 text-center">{title}</h5>
					</div>
				</div>

				<div
					className="card-footer text-center h-100"
					style={{
						visibility: "visible",
						cursor: "default",
					}}
				>
					{scoreObject ? (
						<p className="text-secondary pt-2">
							current maturity Score is{" "}
							{scoreObject ? scoreObject.total_score.toFixed(2) : "0"}
						</p>
					) : (
						<>
							<p className="text-secondary pt-2">current maturity Score is 0</p>
							<p className="text-secondary"> </p>
						</>
					)}
					<>
						{showAIButton && (
							<button
								className="btn btn-sm btn-primary mt-2"
								style={{
									visibility: "visible",
								}}
								onClick={handleAIButtonClick}
								disabled={!enableAIButton}
							>
								Enhance with AI
							</button>
						)}
						&nbsp;
						{!forceHideHistoryButton && (
							<button
								className={`btn btn-sm ${
									showAIButton ? "btn-outline-secondary" : "btn-primary"
								}  mt-2`}
								style={{
									visibility: "visible",
								}}
								disabled={!showHistoryButton}
								onClick={handleHistoryButtonClick}
							>
								History
							</button>
						)}
					</>
				</div>
			</div>
		</div>
	);
};

export default Card;
