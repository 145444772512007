import { useNavigate, useParams } from "react-router-dom";
import { useChat } from "../../../context/chatContext";
import { useCases } from "../../../utils/constants";
import ChatHeader from "../../common/chat/chatHeader";
import ChatHistory from "../../common/chatHistory";
import QuestionsFlow from "../../common/maturityAssessment/questionsFlow";
import {
	framework_domains,
	question_flows,
	user_flow_scores,
} from "../../../utils/endpoints";
import createAxiosInstance from "../../../utils/axiosConfig";
import { useEffect, useState } from "react";
import {
	FlowScore,
	FrameworkDomain,
	QuestionFlow,
} from "../../../utils/interfaces";
import { useAuth } from "../../../context/authContext";
import Footer from "../../common/footer";

const MaturityAreas = () => {
	const { startChat } = useChat();
	const navigate = useNavigate();
	const { frameworkId, domainId } = useParams();
	const { userId } = useAuth();

	const [, setDomains] = useState<FrameworkDomain[]>([]);
	const [selectedDomain, setSelectedDomain] = useState<FrameworkDomain>();
	const [questionFlows, setQuestionFlows] = useState<QuestionFlow[]>([]);
	const [flowScores, setFlowScores] = useState<FlowScore[]>([]);
	const [latestScores, setLatestScores] = useState<FlowScore[]>([]);
	const [refresh, setRefresh] = useState<number>(0);

	const fetchDomains = async (frameworkId: number) => {
		// if (!selectedFramework) return;
		try {
			const domains = await createAxiosInstance().get(
				framework_domains(frameworkId)
			);
			if (domains.status == 401 || domains.status == 403) {
				// 401 is for expired token
				// 403 is for forbidden access (blocked by GPT.)
				console.log("use token expired");
				// logout();
				// navigate("/");
				// window.location.href = "/";
				return;
			}
			setDomains(domains?.data);

			const selectedDomain = domains?.data?.find(
				(domain: FrameworkDomain) => domain.id === Number(domainId)
			);
			console.log(selectedDomain, "selected domain");
			setSelectedDomain(selectedDomain);
		} catch (e) {
			console.log(e);
		}
	};

	const getLatestScores = (
		scores: FlowScore[],
		questionFlows: QuestionFlow[]
	) => {
		const latestScoresMap = new Map<number, FlowScore>();

		// Filter scores to only include those with question_flow_id matching any id in questionFlows
		const validScores = scores.filter((score) =>
			questionFlows.some((flow) => flow.id === score.question_flow_id)
		);

		validScores.map((score) => {
			const existingScore = latestScoresMap.get(score.question_flow_id);
			if (
				!existingScore ||
				new Date(score.score_date) > new Date(existingScore.score_date)
			) {
				latestScoresMap.set(score.question_flow_id, score);
			}
		});

		return Array.from(latestScoresMap.values());
	};

	useEffect(() => {
		fetchDomains(Number(frameworkId));
	}, []);

	useEffect(() => {
		const fetchQuestionsFlow = async () => {
			if (!selectedDomain) return;

			try {
				const questionsFlowResponse = await createAxiosInstance().get(
					question_flows(selectedDomain?.id ?? 0)
				);

				if (
					questionsFlowResponse.status == 401 ||
					questionsFlowResponse.status == 403
				) {
					// 401 is for expired token
					// 403 is for forbidden access (blocked by GPT.)
					console.log("user token expired");
					// logout();
					// navigate("/");
					// window.location.href = "/";
					return;
				}
				setQuestionFlows(questionsFlowResponse?.data);
				flowScores(questionsFlowResponse?.data);
			} catch (e: any) {
				console.log(e);
			}
		};

		const flowScores = async (questionFlows: QuestionFlow[]) => {
			// console.log(selectedFlow, "selected flow");
			if (!selectedDomain) return;

			try {
				const response = await createAxiosInstance().get(
					user_flow_scores(userId ? userId : 1)
				);

				if (response.status == 401 || response.status == 403) {
					// 401 is for expired token
					// 403 is for forbidden access (blocked by GPT.)
					console.log("use token expired");

					// logout();
					// window.location.href = "/";
					// navigate("/");
					return;
				}
				// response.data.filter((score: any) => score.flow_id === questionFlows.id);
				setFlowScores(response.data);

				const latestScores = getLatestScores(response.data, questionFlows);
				setLatestScores(latestScores);
			} catch (e) {
				console.log(e, "Error");
			}
		};

		fetchQuestionsFlow();
	}, [selectedDomain]);

	const handleRefresh = () => {
		setRefresh(refresh + 1);
	};

	return (
		<>
			<ChatHeader
				useCase={useCases[useCases.length - 1].value}
				selectUseCase={startChat}
			/>
			<main className="d-flex flex-column min-vh-65">
				{selectedDomain && (
					<QuestionsFlow
						frameworkId={Number(frameworkId)}
						selectedDomain={selectedDomain}
						questionFlows={questionFlows}
						flowScores={flowScores}
						latestScores={latestScores}
						handleGoBack={() => {
							navigate(-1);
						}}
						handleRefresh={handleRefresh}
					/>
				)}
			</main>
			<Footer />
			<ChatHistory
				onNavigate={() => {
					navigate("/");
				}}
			/>
		</>
	);
};

export default MaturityAreas;
