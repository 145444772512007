import React, { useEffect, useState } from "react";
import LogoIcon from "../../../assets/svg/logoIcon";
import { useCases } from "../../../utils/constants";
import { UseCase } from "../../../utils/interfaces";
import { useAuth } from "../../../context/authContext";

const ChatHeader = ({
	useCase,
	selectUseCase,
}: {
	useCase: number;
	selectUseCase: (selectedCase: number) => void;
}) => {
	const [accountType, setAccountType] = useState<number>(0);
	const [dayGreet, setDayGreet] = useState<string>("");
	const { logout } = useAuth();
	const handleLogout = () => {
		logout();
	};

	useEffect(() => {
		const storedUserData = localStorage.getItem("userData");
		const userData = JSON.parse(storedUserData || "{}");

		if (userData?.account_type) {
			setAccountType(userData?.account_type);
		}

		setDayGreet(getGreeting());
	});

	function getGreeting(): string {
		// Get the current hour of the day
		const today = new Date();
		const hour = today.getHours(); // Returns a number from 0 (midnight) to 23 (11 PM)
		// Determine the appropriate greeting based on the hour
		let greeting: string;
		if (hour >= 0 && hour < 5) {
			greeting = "Hi,";
		} else if (hour > 4 && hour < 12) {
			greeting = "Good morning!";
		} else if (hour < 18) {
			greeting = "Good day!";
		} else {
			greeting = "Good evening!";
		}

		return greeting;
	}

	// const adminUsers = ["zak@1ciso.io", "stan@1ciso.io", "test@turing.com"];
	const userData = JSON.parse(localStorage.getItem("userData") || "{}");

	return (
		<header className="main-header">
			<div className="container">
				<nav className="navbar navbar-expand-lg">
					<div className="container-fluid">
						<a className="navbar-brand" href="/">
							<LogoIcon />
						</a>
						<ul className="navbar-nav me-auto ms-md-5">
							<li className="nav-item">
								<select
									name=""
									id=""
									className="form-select"
									onChange={(e) => {
										selectUseCase(Number(e.target.value));
									}}
									value={useCase}
								>
									<option value="0" disabled>
										Areas of Interest
									</option>
									{useCases.map((useCase: UseCase, key) => (
										<option
											value={useCase.value}
											key={`use_case_options_${key}`}
											selected={useCase.value == Number(useCase)}
										>
											{useCase.name}
										</option>
									))}
								</select>
							</li>
						</ul>
						<div className="d-flex">
							<div className="dropdown">
								<a
									className="btn btn-light dropdown-toggle rounded"
									href="#"
									role="button"
									data-bs-toggle="dropdown"
									aria-expanded="false"
								>
									<i className="bi bi-person"></i>
									<span className="d-none d-sm-inline-block">
										{dayGreet}
										{userData?.first_name && ` ${userData?.first_name} `}
									</span>
								</a>
								<ul className="dropdown-menu">
									<li>
										<a className="dropdown-item" href="/pricing">
											<i className="bi bi-bookmark-star"></i>{" "}
											{accountType == 0
												? "Upgrade to Enterprise "
												: "Enterprise Account"}
											{""}
										</a>
									</li>
									<li>
										<a className="dropdown-item" href="/feedback">
											<i className="bi bi-file-earmark-text"></i> Feedback Form
										</a>
									</li>
									<li>
										<a className="dropdown-item" href="/changeAccountPassword">
											<i className="bi bi-key"></i> Change Password
										</a>
									</li>

									{userData?.is_admin && (
										<li>
											<a className="dropdown-item" href="/ingest">
												<i className="bi bi-robot"></i> Ingest Data
											</a>
										</li>
									)}

									{userData?.is_admin && (
										<li>
											<a className="dropdown-item" href="/uploadFiles">
												<i className="bi bi-file-earmark-arrow-up"></i> Upload
												Files
											</a>
										</li>
									)}
									<li>
										<a className="dropdown-item" href="/privacy-policy">
											<i className="bi bi-file-earmark-lock"></i> Privacy Policy
										</a>
									</li>
									<li>
										<a className="dropdown-item" href="/terms-and-conditions">
											<i className="bi bi-file-earmark-ruled"></i> Terms &
											Conditions
										</a>
									</li>
									<li>
										<a
											className="dropdown-item"
											href="#"
											onClick={handleLogout}
										>
											<i className="bi bi-lock"></i> Log out
										</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</nav>
			</div>
		</header>
	);
};

export default ChatHeader;
