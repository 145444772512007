import { useState } from "react";
import type { FlowScore, QuestionFlow } from "../../../../utils/interfaces";
import { useAuth } from "../../../../context/authContext";

const QuestionFlowList = ({
	flow,
	assessmentHandler,
	AIButtonHandler,
	flowScores,
}: {
	flow: QuestionFlow;
	assessmentHandler: (flow: QuestionFlow) => void;
	AIButtonHandler: (flow: QuestionFlow) => void;
	flowScores: FlowScore[];
}) => {
	const { userData } = useAuth();

	const can_access = (feature: any) => {
		return (
			feature.is_enabled &&
			((userData?.account_type ?? 0 > 0) || feature.is_free)
		);
	};

	return (
		<div
			className={`d-flex ${
				!can_access(flow)
					? "text-secondary questionFlowsListdisabled"
					: "questionFlowsList"
			} `}
			style={{
				marginTop: "2%",
				cursor: can_access(flow) ? "pointer" : "not-allowed",
			}}
		>
			<div
				className={`d-flex flex-column justify-content-between py-4 gap-2`}
				style={{
					cursor: can_access(flow) ? "pointer" : "not-allowed",
					width: "40%",
				}}
				onClick={can_access(flow) ? () => assessmentHandler(flow) : () => {}}
			>
				<span
					className=" card-text text-blue"
					style={{
						textAlign: "left",
						display: "inline !important",
						maxWidth: "max-content",
						fontWeight: 700,
						fontSize: "0.7rem",
					}}
				>
					{flow.description}
				</span>

				<h5
					className="card-title"
					style={{
						width: "100%",
						marginBottom: "2px",
					}}
				>{`${flow.title} `}</h5>
			</div>

			<div
				className="text-end align-self-center text-center"
				style={{ width: "30%" }}
			>
				<span
					style={{
						marginLeft: "2%",
						marginBottom: "2%",
						fontSize: "1rem",
						fontWeight: 500,
						maxWidth: "fit-content",
					}}
					className={`badge bg-light  ${
						can_access(flow) ? `text-blue flow-list-item` : `text-secondary`
					} mt-auto`}
				>
					score = {flowScores.length > 0 ? flowScores[0]?.score.toFixed(1) : 0}
				</span>
				<br />
				{flowScores.length > 0 ? (
					<span className="text-secondary">
						{new Intl.DateTimeFormat("en-us").format(
							new Date(flowScores[0]?.score_date)
						)}
					</span>
				) : (
					""
				)}
			</div>

			<div style={{ width: "30%" }} className="text-end align-self-center">
				<button
					className="btn btn-primary mt-auto"
					style={{
						visibility: "visible",
					}}
					disabled={
						userData?.account_type !== undefined && userData?.account_type === 0
					}
					onClick={
						flowScores.length > 0
							? can_access(flow) &&
							  userData?.account_type !== undefined &&
							  userData?.account_type > 0
								? () => AIButtonHandler(flow)
								: () => {}
							: can_access(flow)
							? () => assessmentHandler(flow)
							: () => {}
					}
				>
					{flowScores.length > 0 ? "Enhance with AI" : "Start Assessment"}
				</button>
			</div>
		</div>
	);
};

export default QuestionFlowList;
