import React, { useState } from "react";
import moment from "moment";

const ScoreHistory = ({
	isOpen,
	handleClose,
	loading,
	content = {
		last_month: [],
		last_week: [],
		last_quarter: [],
	},
}: {
	isOpen: boolean;
	handleClose: () => void;
	loading: boolean;
	content: { last_month: any[]; last_week: any[]; last_quarter: any[] };
}) => {
	const [visibleCounts, setVisibleCounts] = useState({
		last_month: 3,
		last_week: 3,
		last_quarter: 3,
	});

	if (!isOpen) return null;

	const renderDomainScores = (domains: any[], date: any) => {
		const formattedTime = moment(date, "dd/mm/yyyy h:mm:ss A").format(
			"MM/DD/YYYY h:mm A"
		);
		return (
			<ul className="list-group list-group-flush mb-5">
				{domains.map((domain, index) => (
					<li
						key={index}
						className="list-group-item "
						data-bs-dismiss="offcanvas"
						aria-label="Close"
						style={{ cursor: "pointer", borderBottom: "1px solid gray" }}
					>
						<b style={{ fontSize: "1.1rem" }}>
							{domain.domain_title || domain.category_title}
						</b>{" "}
						score on{" "}
						<small className="text-muted datetime">{formattedTime}</small> was{" "}
						<span
							className="text-primary"
							style={{ fontSize: "1.1rem", fontWeight: 500 }}
						>
							{domain.total_score.toFixed(2)}
						</span>{" "}
					</li>
				))}
			</ul>
		);
	};

	const renderContent = (timePeriod: string, data: any[]) => {
		if (data.length === 0) return;
		const visibleData = data.slice(
			0,
			visibleCounts[timePeriod as keyof typeof visibleCounts]
		);

		return (
			<div style={{ marginBottom: "12%" }}>
				<h5 className="text-blue text-primary">
					<i className="bi bi-calendar"></i>{" "}
					{timePeriod.replace("_", " ").toUpperCase()}
					<span
						style={{
							marginLeft: "2%",

							marginBottom: "10%",
							fontSize: "0.9rem",
							fontWeight: 500,
						}}
						className="badge bg-light text-blue"
					>
						{data.length}
					</span>
				</h5>

				{visibleData.map((entry, index) => {
					const date = Object.keys(entry)[0];
					const domains = entry[date];
					return (
						<div key={index} className="d-flex flex-column" style={{}}>
							{/* <h6 className="text-secondary align-self-end">{date}</h6> */}
							{renderDomainScores(domains, date)}
						</div>
					);
				})}
				{data.length >
					visibleCounts[timePeriod as keyof typeof visibleCounts] && (
					<button
						className="btn btn-link"
						onClick={() =>
							setVisibleCounts((prevCounts) => ({
								...prevCounts,
								[timePeriod]:
									prevCounts[timePeriod as keyof typeof prevCounts] + 3,
							}))
						}
					>
						Show More
					</button>
				)}
			</div>
		);
	};

	return (
		<>
			<div className="offcanvas-backdrop fade show" onClick={handleClose}></div>

			<div
				className="offcanvas offcanvas-end show"
				tabIndex={-1}
				id="offcanvasExample"
				aria-labelledby="offcanvasExampleLabel"
			>
				<div className="offcanvas-header">
					<h5 className="offcanvas-title" id="offcanvasExampleLabel">
						LLM History
					</h5>
					<button
						type="button"
						className="btn-close"
						data-bs-dismiss="offcanvas"
						aria-label="Close"
						onClick={handleClose}
					></button>
				</div>

				<div className="offcanvas-body scroller">
					{loading ? (
						<div className="spinner-border" role="status">
							<span className="visually-hidden">Loading...</span>
						</div>
					) : (
						<>
							<div className="px-3">
								{Object.keys(content).map((timePeriod) => (
									<div key={timePeriod}>
										{renderContent(
											timePeriod,
											content[timePeriod as keyof typeof content]
										)}
									</div>
								))}
							</div>
							<br />
						</>
					)}
				</div>
			</div>
		</>
	);
};

export default ScoreHistory;
