import React, { useEffect, useState } from "react";
import ChatHeader from "../../common/chat/chatHeader";
import ChatHistory from "../../common/chatHistory";
import { useCases } from "../../../utils/constants";
import { useChat } from "../../../context/chatContext";
import { useAuth } from "../../../context/authContext";
import { useNavigate, useParams } from "react-router-dom";
import Questions from "../../common/maturityAssessment/questions";
import createAxiosInstance from "../../../utils/axiosConfig";
import {
	get_questions_by_flow_id,
	question_flows,
} from "../../../utils/endpoints";
import { Question, QuestionFlow } from "../../../utils/interfaces";
import Alert from "../../common/alerts/alert";
import Footer from "../../common/footer";

const MaturityQuestionnaire = () => {
	const { startChat } = useChat();
	const navigate = useNavigate();
	const { domainId, questionFlowId } = useParams();
	const { logout } = useAuth();
	const [selectedFlow, setSelectedFlow] = useState<QuestionFlow>();
	const [questions, setQuestions] = useState<Question[]>([]);

	const [alertShow, setAlertShow] = useState<boolean>(false);
	const [alertMessage, setAlertMessage] = useState<string>("");
	const [alertType, setAlertType] = useState<
		| "primary"
		| "secondary"
		| "success"
		| "danger"
		| "warning"
		| "info"
		| "light"
		| "dark"
	>("danger");

	const handleQuit = () => {
		// setAssessmentStarted(false);
		setQuestions([]);
		setAlertShow(false);
		navigate(-1);
	};

	const handleAlert = ({
		message,
		show = true,
		type = "danger",
	}: {
		message: string;
		show: boolean;
		type:
			| "primary"
			| "secondary"
			| "success"
			| "danger"
			| "warning"
			| "info"
			| "light"
			| "dark";
	}) => {
		console.log("showing alert 1");
		setAlertMessage(message);
		setAlertShow(show);
		setAlertType(type);
	};

	const fetchQuestionsFlow = async () => {
		if (!domainId) return;

		try {
			const questionsFlowResponse = await createAxiosInstance().get(
				question_flows(Number(domainId))
			);

			if (
				questionsFlowResponse.status == 401 ||
				questionsFlowResponse.status == 403
			) {
				// 401 is for expired token
				// 403 is for forbidden access (blocked by GPT.)
				console.log("user token expired");
				// logout();
				// navigate("/");
				// window.location.href = "/";
				return;
			}

			// Loop through the question flows and select the one that matches the questionFlowId
			const flows = questionsFlowResponse.data;
			const matchingFlow = flows.find(
				(flow: QuestionFlow) => flow.id === Number(questionFlowId)
			);
			setSelectedFlow(matchingFlow);
			// setQuestionFlows(questionsFlowResponse?.data);
			// flowScores(questionsFlowResponse?.data);
		} catch (e: any) {
			console.log(e);
		}
	};

	useEffect(() => {
		const getQuestions = async () => {
			if (!questionFlowId) return;

			try {
				const response = await createAxiosInstance().get(
					get_questions_by_flow_id(Number(questionFlowId))
				);
				if (response.status == 401 || response.status == 403) {
					// 401 is for expired token
					// 403 is for forbidden access (blocked by GPT.)
					console.log("user token expired");
					logout();
					// window.location.href = "/";
					// navigate("/");
					return;
				}

				setQuestions(response.data);
			} catch (e: any) {
				if (e.response.status == 402) {
					console.log(e.response.data.detail);
					handleQuit();
					setAlertShow(true);
					setAlertMessage(e.response.data.detail);
					setAlertType("danger");
					return;
				}
				console.log(e);
			}
		};

		getQuestions();
		fetchQuestionsFlow();
	}, [questionFlowId]);

	return (
		<>
			<ChatHeader
				useCase={useCases[useCases.length - 1].value}
				selectUseCase={startChat}
			/>
			<main className="d-flex flex-column min-vh-65">
				<div
					className="authentication card-main h-100 align-self-center"
					style={{ marginTop: "2%" }}
				>
					{questionFlowId && selectedFlow && (
						<Questions
							selectedFlow={selectedFlow}
							questions={questions}
							handleQuit={handleQuit}
							handleAlert={(alert: { message: any; show: any; type: any }) =>
								handleAlert({
									message: alert.message,
									show: alert.show,
									type: alert.type,
								})
							}
						/>
					)}
				</div>
				<Footer />
			</main>
			<ChatHistory
				onNavigate={() => {
					navigate("/");
				}}
			/>
			<Alert
				message={alertMessage}
				show={alertShow}
				onClose={() => setAlertShow(false)}
				type={alertType}
			/>
		</>
	);
};

export default MaturityQuestionnaire;
