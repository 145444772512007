import axios, { AxiosRequestConfig } from "axios";

const createAxiosInstance = (
	customHeaders: AxiosRequestConfig["headers"] = {}
) => {
	const token = localStorage.getItem("token");
	const defaultHeaders: any = {};

	if (token) {
		defaultHeaders.Authorization = `Bearer ${token}`;
	}

	const headers = { ...defaultHeaders, ...customHeaders };

	return axios.create({
		// baseURL: "https://chat.1ciso.io/backend",
		// baseURL: "http://localhost:8000/",
		baseURL:
			process.env.REACT_APP_SERVER_URL || "https://chat.1ciso.io/backend",
		headers,
	});
};

export default createAxiosInstance;
// import axios from "axios";

// // Create an Axios instance
// const axiosInstance = axios.create({
//   baseURL: "http://localhost:8000", // Replace with your API base URL
// });

// Request interceptor to add the token from local storage
// axiosInstance.interceptors.request.use(
//   (config) => {
//     const token = localStorage.getItem("token"); // Get the token from local storage
//     if (token) {
//       config.headers.Authorization = `Bearer ${token}`;
//     } else {
//       // Redirect to '/' if the token is not present
//       window.location.href = "/";
//     }
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

// // Response interceptor to handle errors
// axiosInstance.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   (error) => {
//     if (error.response.status === 401) {
//       // Redirect to '/' if the response status is 401 Unauthorized
//       window.location.href = "/";
//     }
//     return Promise.reject(error);
//   }
// );

// export default axiosInstance;
