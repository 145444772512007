import React, { createContext, useState, useContext } from "react";
import { useAuth } from "./authContext";
import createAxiosInstance from "../utils/axiosConfig";
import { nist_frameworks, start_chat } from "../utils/endpoints";

interface ChatContextType {
	selectedUseCase: number;
	selectedSubCase: string | undefined;
	chatId: string;
	renderFromHistory: boolean;
	conversation: any;
	showCards: boolean;
	setSelectedUseCase: (useCase: number) => void;
	setSelectedSubCase: (subCase: string | undefined) => void;
	setChatId: (id: string) => void;
	setRenderFromHistory: (render: boolean) => void;
	setConversation: (conversation: any) => void;
	setShowCards: (show: boolean) => void;
	resumeChat: (conversation: any, navigateCallback: () => void) => void;
	resetChatState: () => void;
	startChat: (usecase_value: number) => void;
}

const ChatContext = createContext<ChatContextType | undefined>(undefined);

export const ChatProvider: React.FC<{ children: React.ReactNode }> = ({
	children,
}) => {
	const { logout } = useAuth();
	const { isAuthenticated } = useAuth();
	const [selectedUseCase, setSelectedUseCase] = useState<number>(0);
	const [selectedSubCase, setSelectedSubCase] = useState<string | undefined>();
	const [chatId, setChatId] = useState<string>("");
	const [renderFromHistory, setRenderFromHistory] = useState<boolean>(false);
	const [conversation, setConversation] = useState<any>(null);
	const [showCards, setShowCards] = useState<boolean>(true);
	const [isSelecting, setIsSelecting] = useState<boolean>(false);

	const resetChatState = () => {
		setSelectedUseCase(0);
		setSelectedSubCase(undefined);
		setChatId("");
		setRenderFromHistory(false);
		setConversation(null);
		setShowCards(true);
	};

	React.useEffect(() => {
		if (!isAuthenticated) {
			resetChatState();
		}
	}, [isAuthenticated]);

	const startChat = async (index: number) => {
		if (isSelecting) return;
		setIsSelecting(true);
		if (index !== 2) {
			try {
				const response = await createAxiosInstance().post(start_chat);
				if (response.status == 401 || response.status == 403) {
					// 401 is for expired token
					// 403 is for forbidden access (blocked by GPT.)
					console.log("use token expired");
					logout();
					window.location.href = "/";
					return;
				}
				setSelectedUseCase(index);
				setSelectedSubCase(undefined);
				setShowCards(false);
				// setShowNistCards(false);
				setRenderFromHistory(false);
				// setMessages([]);
				setChatId(response?.data?.chat_id);
			} catch (error: any) {
				console.error("Error selecting use case:", error);
				if (error?.response?.status == 401 || error?.response?.status == 403) {
					console.log("use token expired");
					logout();
					window.location.href = "/";
					return;
				}
			} finally {
				setIsSelecting(false);
			}
		} else {
			// user selected maturity assessment use case....
			setShowCards(true);
			// setShowNistCards(true);
			setSelectedUseCase(index);

			try {
				const response = await createAxiosInstance().get(nist_frameworks);
				if (response.status == 401 || response.status == 403) {
					// 401 is for expired token
					// 403 is for forbidden access (blocked by GPT.)
					console.log("use token expired");
					logout();
					window.location.href = "/";
					return;
				}
				// setFrameworks(response?.data);
				console.log(response?.data);
			} catch (error: any) {
				console.error("Error fetching maturity assessment frameworks:", error);
			} finally {
				setIsSelecting(false);
			}
		}
	};

	const resumeChat = (conversation: any, navigateCallback: () => void) => {
		let label = conversation?.conversation[0]?.label.split("_");
		let useCase = Number(label[label.length - 1]);
		let subCase = "";
		if (label[label.length - 1].includes(".")) {
			const useCaseParts = label[label.length - 1].split(".");
			useCase = Number(useCaseParts[0]);
			subCase = useCaseParts[1];
			setSelectedSubCase(subCase);
		}
		setSelectedUseCase(useCase);
		setChatId(conversation?.id);
		setRenderFromHistory(true);
		setConversation(conversation);
		setShowCards(false);

		// Call the navigate callback
		navigateCallback();
	};

	return (
		<ChatContext.Provider
			value={{
				selectedUseCase,
				selectedSubCase,
				chatId,
				renderFromHistory,
				conversation,
				showCards,
				setSelectedUseCase,
				setSelectedSubCase,
				setChatId,
				setRenderFromHistory,
				setConversation,
				setShowCards,
				resumeChat,
				resetChatState,
				startChat,
			}}
		>
			{children}
		</ChatContext.Provider>
	);
};

export const useChat = () => {
	const context = useContext(ChatContext);
	if (context === undefined) {
		throw new Error("useChat must be used within a ChatProvider");
	}
	return context;
};
